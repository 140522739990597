import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons/faSquareTwitter";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";

import "./index.css";

export default function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      {!window.location.href.endsWith("/instructions") && (
        <footer className="bg-duck-darkYellow p-16 border-t-4 border-duck-yellow">
          <h3 className="font-black text-center uppercase mb-4">
            The countdown begins
          </h3>
          <p className="text-center text-sm mb-16">
            We're launching the first-ever ducking enthusiasts smartphone app
            June 2025.
          </p>
          <ul className="w-full mb-16 flex flex-row items-center justify-center gap-2">
            <li>
              <a
                className="social-link icon-link"
                href="https://instagram.com/jeepduckit"
              >
                <FontAwesomeIcon icon={faInstagram} className="w-8 h-8" />
              </a>
            </li>
            <li>
              <a
                className="social-link icon-link"
                href="https://twitter.com/jeepduckit"
              >
                <FontAwesomeIcon icon={faSquareTwitter} className="w-8 h-8" />
              </a>
            </li>
            <li>
              <a
                className="social-link icon-link"
                href="https://facebook.com/jeepduckit"
              >
                <FontAwesomeIcon icon={faFacebookSquare} className="w-8 h-8" />
              </a>
            </li>
            <li>
              <a
                className="social-link icon-link"
                href="https://www.tiktok.com/@duckfatherduckit"
              >
                <FontAwesomeIcon icon={faTiktok} className="w-8 h-8" />
              </a>
            </li>
          </ul>
          <ul className="w-full flex flex-row justify-center items-center text-[#7c700d] text-xs gap-4">
            <li>
              <a href="https://jeepduckit.com">jeepduckit.com</a>
            </li>
            <li>
              <a
                href="https://app.websitepolicies.com/policies/view/t8vqd8px"
                rel="nofollow"
              >
                Acceptable Use Policy
              </a>
            </li>
            <li>
              <a href="mailto://support@jeepduckit.com">Support</a>
            </li>
            <li className="copyright">
              &copy;2022 DuckIt. All Rights Reserved.
            </li>
          </ul>
        </footer>
      )}
    </div>
  );
}
