import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { useParams } from "react-router";

export default function User() {
  const { userId } = useParams();

  const mainClasses =
    "grow bg-duck-yellow h-full flex flex-col text-center items-center justify-center px-8 lg:px-32";
  const [user, setUser] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href = "market://details?id=com.jeepduckit.duckit";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href =
        "itms-apps://itunes.apple.com/app/com.jeepduckit.duckit";
    }
  }, []);

  useEffect(() => {
    if (!userId) return;

    (async function fetchUser() {
      const url = `${process.env.REACT_APP_SERVER_URL}/member/${userId}`;
      const result = await fetch(url);

      if (!result.ok) {
        setIsError(true);
        return;
      }

      const body = await result.json();
      setUser(body);
    })();
  }, [userId]);

  if (isError) {
    return (
      <main className={mainClasses}>
        <span className="text-6xl font-black mb-16">Uh oh!</span>
        <span className="text-xl">
          Something went wrong! Try scanning that QR code again, and make sure
          the QR code is illuminated and clean.
        </span>
      </main>
    );
  }

  if (user === null) {
    return (
      <main className={mainClasses}>
        <FontAwesomeIcon icon={faSpinner} pulse={true} size="4x" />
      </main>
    );
  }

  return (
    <main className={mainClasses}>
      <div className="mb-16 flex flex-row gap-16 items-center justify-center">
        <img
          src={`data:image/png;base64,${user.image}`}
          alt=""
          className="w-32 h-32 rounded-full"
        />
        <span className="text-7xl font-bold">{user.name}</span>
      </div>
      <span className="text-xl">
        Connect with {user.name} on Duck It! Download the app today!
      </span>
      <div className="flex flex-row gap-8 mt-16">
        <a href="https://play.google.com/store/apps/details?id=com.jeepduckit.duckit">
          <img
            className="h-20"
            src="/img/google-play-badge.png"
            alt="Google Play Store logo"
          />
        </a>
        {/* TODO */}
        <a href="https://itunes.apple.com/app/idFILL_ME_IN">
          <img
            className="h-20"
            src="/img/app-store-badge.png"
            alt="Apple App Store logo"
          />
        </a>
      </div>
    </main>
  );
}
